import { useDispatch, useSelector } from 'react-redux';
import { setMenu } from '../../redux/slices/menuSlice';
import Profile from './profile';
import { RootState } from '../../redux/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { setSearch } from '../../redux/slices/searchSlice';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { setInstitutionHidden } from '../../redux/slices/hideInstitutionSlice';
import InstitutionName from '@/components/header/institutionName';
import Notifications from '@/components/header/notifications';

const Header = () => {
  const dispatch = useDispatch();
  const hideInstitution = useSelector(
    (state: RootState) => state.hideInstitution,
  );
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/institutions')) {
      dispatch(setInstitutionHidden(true));
    } else {
      dispatch(setInstitutionHidden(false));
    }
  }, [location, dispatch]);

  return (
    <header className='relative mb-8 print:hidden'>
      <div className='flex items-center justify-between py-5'>
        <div className='flex items-center'>
          <button
            name='mobileMenuToggle'
            className='lg:hidden ignoreClickOutside flex flex-col gap-1 w-10 cursor-pointer'
            onClick={() => dispatch(setMenu(true))}
            data-cy='navMobileToggle'>
            <div className='w-10 bg-app-800 h-1 rounded-lg'></div>
            <div className='bg-app-800 w-4/6 h-1 rounded-lg'></div>
            <div className='w-10 bg-app-800 h-1 rounded-lg'></div>
          </button>
          {!hideInstitution && <InstitutionName />}
        </div>
        <div className='flex items-center gap-4'>
          <button
            title='search'
            onClick={() => dispatch(setSearch(true))}
            className='p-2 ignoreClickOutside'>
            <FontAwesomeIcon icon={faSearch} size='lg' />
          </button>
          <Notifications />
          <Profile />
        </div>
      </div>
      <hr className='bg-app-800 rounded-md opacity-60' />
    </header>
  );
};

export default Header;
